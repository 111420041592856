import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Teitur = () => {
  return (
    <Layout>
      <Head title="Teitur Magnússon" />
      <h3>Teitur Magnússon</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Iceland
      </h4>
      <p>
        <OutboundLink href="https://www.teiturmagnusson.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.facebook.com/teiturmagnussonmusic">
          Facebook
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.youtube.com/channel/UC6sm-xQUv_QrW87HMMSIBXw">
          YouTube
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://open.spotify.com/artist/706sAHx4XxStUJXR3HKesQ">
          Spotify
        </OutboundLink>
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665004610/artnow/teitur/3-min.jpg"
        alt="Teitur Magnússon from Iceland on artnow.world"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665003582/artnow/oneoffs/att1.jpg"
        alt="Edward Elvarez interview of musician Teitur Magnússon for artnow.world"
      />
      <h3>Interview by Edward Alvarez</h3>
      <p>
        Teitur Magnússon is a legendary musician from Iceland. It was an
        incredible experience speaking with him. Besides his solo work he also
        has proven himself as an excellent Reggae artist. His music is
        constantly evolving.Teitur makes some of the most timeless, classic
        songs which I never get tired of listening to. While it may seem music
        comes naturally to him, he reminded me that it took a lot of hard work.{" "}
      </p>

      <p>
        <b>Edward Alvarez</b>: The interesting thing about your music is that I
        don't understand the Icelandic language at all, but every time I listen
        to the songs that you make I seem to really enjoy them, and I think
        that's really interesting because usually we tend to listen to music
        that's in our own language, because it's what we understand. You have a
        natural talent for what you do, and there's so many songs- I actually
        kind of lose track of the songs. Usually when you have your favorite
        song, you remember what the title is, but since it's a different
        language sometimes I forget. I was wondering, have you had that
        experience? I don't know if you listen to a lot of American music, but
        does it seem the same way to you? Or do you have a different experience?
      </p>

      <p>
        <b>Teitur Magnússon</b>: Different experience...I appreciate that. Yea,
        I understand English, But you know as a kid I didn't. But children
        everywhere in the world love for example The Beatles when they get to
        know them at a certain age, even though they don't understand the words,
        and often learn English through songs like the Yellow Submarine, etc. So
        yea, maybe children everywhere young and old can connect through music
        and playfulness.
      </p>

      <p>EA: Were you inspired by Jimi Hendrix? </p>

      <p>
        TM: Yes, very much. He was my favorite musician when I was starting out
        on the guitar, when I was like 10 years old.
      </p>

      <p>EA: That's really cool, yea he's one of my favorite musicians. </p>

      <p>
        TM: Yea, yea I love him. He's really influential, and then I got for a
        while into John Frusciante from the Chili Peppers. There's a lot of
        guitarists from the 90s that are also influenced from Jimi Hendrix and
        kind of take his style to another place.{" "}
      </p>

      <p>
        EA: Yea, John Frusciante, I really like him. Some of his life story is
        really interesting if you read about him, he's gone through so much
        difficult times, but he still makes music...So one of my all-time
        favorite songs that you've made is called Allt líf, I think it's the
        last song on the 27 album. There's just something about that song, I
        can't really explain it, but I remember the first few times that I heard
        it, it just hit me a certain way. And actually I couldn't find it for a
        while, I almost thought the album was hard to find, and then ironically
        I found it on bandcamp recently. How was making that album? That was
        your first album?
      </p>

      <p>
        TM: The reggae band and a whole lot of other bands, they had gone
        through many genres so to say, and ended up with this kind of a mixture
        of sounds, it became my solo album.{" "}
      </p>

      <p>
        What happened was there was this British, English guy living in Iceland
        at the time and he wanted to meet up and do something together. We met
        up and I showed him some of the songs that I was working on and we
        started recording three of them, and then I just wrote some more with
        the help of my cousin, he was helping me out with the lyrics. And then
        we just finished that album the summer of 2014. And then this guy Mike
        Lindsay he was moving back to England, so he left after we had finished
        the album. It happened really fast and spontaneous. So it has a lot of
        rawness to it, that first album. My collaborator Arnljótur from Ojba
        Rasta helped me out a lot with those first two solo albums, playing many
        of the instruments, he has since focused on electronica and just
        released a record with his project Kraftgalli.{" "}
      </p>

      <p>
        EA: That's interesting. Your other album was 33? Did Jón Sæmundur do the
        album art?{" "}
      </p>

      <p>
        TM: Yes. I can see you have his artwork there behind you. That's cool.
      </p>

      <p>
        EA: Yea, I look at it sometimes. It's like a spirit that he was trying
        to put on to the paper, it does have that quality to it sometimes, like
        there's some sort of energy I guess as well.{" "}
      </p>

      <p>
        TM: Me and him, we met doing some kind of spiritual gathering around
        campfires. And then one time he came to my birthday and gave me this
        little painting that was made of me on a page from a book about
        Esperanto. That painting became the cover later on for the album...We
        became really good friends a couple of years ago.
      </p>

      <p>EA: Does he live near you, are you his neighbor?</p>

      <p>TM: Five minutes maybe.</p>

      <p>
        EA: The other songs that I really like that you've made are Kamelgult,
        and there's this other song that you made called Hvíti dauði. It was the
        music video of the lighthouse. I think I'm not pronouncing it correctly,
        it's the old man and the lighthouse. I think the video description said
        it was something about a documentary. Throughout the video there's
        elderly people and they're doing different tasks and stuff...It was a
        very strange experience that I had last night, I was talking to my
        friend who's almost 100 years old, he's not doing very well and he was
        on my mind, so I was going to listen to some of your music to unwind. I
        have all the bookmarks of your songs and I just clicked on a random one
        and it was that song, I actually hadn't listened to that song for a few
        months and so it seemed very, it was like some sort of synchronicity,
        like a coincidence when everything lines up and I don't know, it's just,
        it was a very good song and it made me think about things. Is that what
        you were going for with that?
      </p>

      <p>
        TM: Yea, what happened was I don't remember that much about writing that
        song, it kind of came to me. And then at the same time when I was
        writing, I was reading some old stuff from the Bible and an old poet who
        had a very rough life, a couple hundred years ago. He worked all his
        life, he was born to a really young mother who left him somewhere and
        made to work his whole life. He worked really hard until his body
        collapsed and then he also had to keep on working back in those days.
        But he was really clever and really good, he wrote a lot of poetry and
        some of it is in that song; and he's just talking about how he's longing
        for death in those lyrics...Like it would be good to rest from this
        aging body, and there is something to it that, I thought it was
        interesting. I wrote it in January when it was super snowy here,
        everything was white and I had been thinking about- nature goes through
        death and rebirth, like maybe Halloween, or the Day of the Dead. Like
        for example, here when everything starts to go into hibernation a lot of
        plants die, and then get born again in the spring. So it was in this
        kind of circular thinking, these lyrics and the song got the title
        principally like this. Yea, I wanted to make maybe a little emotional
        song or emo kind of but...maybe that is also the Dead mantra if you're
        familiar with that, how does it go? One who fears death cannot enjoy
        life? And then we put out the song and a friend of mine was making this
        documentary about his grandfather, the lighthouse keeper, and wanted me
        to help him out, do some music for the documentary. And I asked him in
        return to use some of the footage for this video. We put it out and like
        around the same time, you know covid was happening, coming into Europe
        almost, and I wanted to, when I was asked to perform on the TV here in
        Iceland, I wanted to do that song...But they asked me not to do that
        song, they thought it would be too triggering...How people might be very
        afraid, that people are really scared...{" "}
      </p>

      <p>
        EA: I had a lot of things on my mind when I was listening to it, and I
        kind of cried listening to it last night. I was sad about some things,
        but it was also uplifting and there was something positive to it, it
        gave me some sort of hope as well, so I thought that was really
        meaningful.{" "}
      </p>

      <p>TM: Good to know, thank you.</p>

      <p>
        EA: Were you referring to Jon Saemundur's quote, "He who fears death
        cannot enjoy life"
      </p>

      <p>TM: Yea, yea. </p>

      <p>
        EA: Yea, I totally understand what you're saying...Well anyway, it ties
        into some of my other questions, revisit it in a second. So maybe you
        could tell me a little bit about your Rasta band? It was kind of a
        surprise to me to find your reggae music. I didn't know about it, I just
        kind of found out about it recently, I didn't know you were doing that.
      </p>

      <p>
        TM: Yea, well, reggae was one of the first music I got exposed to as a
        kid, my mom listened to it a lot and then my sisters, and then I guess
        there started to be some sort of a scene around it. Maybe 2004 there was
        this band here that got really popular...And then we got together, a
        couple friends...We wanted to do originally focus on like dub music sort
        of...a lot of players on stage, big collective, you know, keyboard
        players and drummers...and then I really started to write songs. I had
        that old song called Jolly Good, which I had written as a kid, which was
        just a simple three chord song, very kind of influenced by maybe UK
        reggae from back in the day. We put that in the show and then we started
        to sing, when you write songs, and that's where I honed my skills...You
        actually have so many elements to play with in different directions and
        you get the right lyrics and chord structures, but simplicity is also
        very important in it, the groove. And it's fun to play live, people can
        dance to it or they can sit and listen and watch, very applicable to any
        situation.
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xHBbFboUA9M"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        EA: Oh cool, yea I was listening to one of your, I think it was with
        KEXP in Iceland, it was a live concert and you were playing a Telecaster
        guitar. I just want to say your guitar playing is really amazing, it was
        almost kind of bluesy, you're such a good guitar player. And it's
        interesting because at first when I started listening to your music I
        didn't really know that you played guitar that much, but since I've been
        listening to you more I'm just kind of blown away by how you play
        guitar, it's really good.{" "}
      </p>

      <p>TM: Oh thank you man. </p>

      <p>
        EA: I was going to ask you about that. How did you learn guitar? Did you
        put a lot of work into it?
      </p>

      <p>
        TM: Yea. Started when I was like 10 years old, I went to study in a
        group, it was like a group session where everybody had headphones and
        sat on a desk, went through the chords with a teacher...And then they
        also had these teachers later on, they were kind of cool, like these
        older rockers. They asked, what are you listening to? What songs do you
        like at the moment? And you show them and they taught you how to play
        them, which is a great way to learn, just learn whatever songs you like.
        Then later on I tried going to the official schools and I learned a lot
        of things but also they kind of make you study stuff that you don't even
        necessarily connect with, and that's when I kind of lost interest in
        formal musical education. But I learned some things here and there, and
        also learned a lot of playing with other people, and listening to a lot
        of music. I remember once...when I was maybe 13...I didn't have a
        driver's license, I didn't know anybody around here. So I had a lot of
        time for myself, there were no other kids, no distractions. I just
        played a lot of guitar and remember how much I improved. That's how I
        learned, I just listened to records and tried to pick up what I heard,
        like solos and stuff.
      </p>

      <p>
        EA: I saw you were looking at your vinyl record there earlier, the Orna
        album. It's really cool, I have been listening to that too. What is it
        like having your music on vinyl? That seems kind of special.{" "}
      </p>

      <p>
        TM: Yea yea, it's fun and it's really cool. It is also kind of daunting
        when you have a lot of, like a new album that you put out, and you have
        a lot of it, you just see all the copies, you think like wow, now I
        gotta get these out there, you know what I mean? Don't want them just
        sitting there...
      </p>

      <p>
        EA: Oh I was going to ask you, there's this place where you could get
        information about all the vinyl records and it says that Orna was 45
        RPM, is that true?
      </p>

      <p>TM: Yea, yea.</p>

      <p>
        EA: Oh wow. There's some versions of LPs that are 45 but I guess it's
        kind of uncommon, but that's cool...Yea I really wish I, I I've recorded
        some music too, I mean I'm not really that good on guitar but I always
        kind of have that wish like, it would be really cool to get this on
        vinyl, but it's impossible. So that's why I was saying, maybe it
        probably feels pretty cool to have your music on that type of medium or
        whatever.
      </p>

      <p>TM: One guy I know here he can make limited copies.</p>

      <p>
        EA: Yea that's a good point. What do you think about the idea of
        perfection versus imperfection? Do you have any thoughts on that?{" "}
      </p>
      <p>
        TM: Yea, it's an interesting balance to have in mind. Sometimes you have
        to give yourself a deadline to reach that balance, and also you might
        have to consolidate with other people, work with other people...In the
        end it's about also the listener. And today it's really easy to make
        things perfect, type of technology, but also you might sterilize
        something by doing it. So yea, I think it's important for me to just
        really try to do my best, even though I do feel my best is not going to
        be perfect.{" "}
      </p>

      <p>
        EA: I think it's really important what you're saying about balance,
        because I think so often people want things to be perfect, but I think
        imperfection can have some value too. And I think it is worthwhile for
        there to be both maybe...Some people say it's impossible for things to
        be perfect, so maybe just try your best is really what the focus should
        be on.{" "}
      </p>

      <p>
        TM: Yea, I think also the viewer can sense if you've given it your all,
        you know? It's about connection...people feeling your intention or if
        you have respect...goes a long way.{" "}
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665004610/artnow/teitur/4-min.jpg"
        alt="Teitur Magnússon from Iceland on artnow.world"
      />

      <p>
        EA: It's really cool that you understand imperfection, like that you
        have thoughts on it. There's an American poet whose name was Wallace
        Stevens, he was 1900s, one of his famous lines was I think,
        "imperfection is our paradise" or something like that...He had this idea
        that the imperfect has some value...Some people would think that doesn't
        mean anything but it does, I guess that's what I'm trying to say.
      </p>

      <p>TM: Mhm...Yea, it's also subjective of course.</p>

      <p>
        EA: Right. Yea, that's a good point. What other American music have you
        listened to?
      </p>

      <p>TM: A lot. Most of it. Yea, I don't know where to begin.</p>

      <p>
        EA: Are there a lot of other musicians in Iceland, other writers or
        musicians?{" "}
      </p>

      <p>
        TM: A lot of musicians in Iceland, yea. I was at a concert yesterday
        with my friend, he was putting out an album and he had a pretty good
        band with him, some good vibes going on. There's a little bit of
        everything here, a small population but there's often like yea a little
        bit of everything...a lot of different stuff, different genres.{" "}
      </p>

      <p>
        EA: I like what you were talking about earlier, about when you were
        learning to play guitar that you were jamming with other people and
        stuff. As an artist do you feel that it could be difficult or
        challenging to work with other people sometimes?
      </p>

      <p>
        TM: Of course, it can be...I mean yea, can be a hassle in general in
        life, but musically you kind of find your people to work with, find out
        pretty fast if you're on the same page musically. For some reason I feel
        like that has never been much of a problem.{" "}
      </p>

      <p>
        EA: Okay yea, exactly, right. I hear you on that. I think one of the
        challenges for artists sometimes is that they want to close themselves
        off from everything and just do things on their own. I've always enjoyed
        playing guitar with other people, and there is kind of like a synergy
        that happens where you're bouncing ideas off of each other, and so I
        think that it's really important sometimes to have that experience.{" "}
      </p>

      <p>
        TM: Yes, really important. All my stuff is done through collaboration.
        It's really important to me.{" "}
      </p>

      <p>
        EA: I get the impression that you're really easy going in your life, and
        I was wondering, how do you deal with stress? Do you ever have stressful
        situations?
      </p>

      <p>
        TM: Yea, you just have to breathe, remember to breathe. Yea, breathe and
        don't panic, that's all.{" "}
      </p>

      <p>
        EA: Oh I like that. Yea, I like that a lot. Have you ever been to the
        United States?
      </p>

      <p>TM: Yes, I've been to New York and LA. </p>

      <p>EA: Oh nice. Yea that's really cool. Were those good experiences?</p>

      <p>TM: Yea, it was fun.</p>

      <p>EA: LA can be a little bit hectic sometimes.</p>

      <p>
        TM: Yea and those are just a bit crazy, because these huge
        cities...Where I'm from, I don't think I would like to live there.{" "}
      </p>

      <p>
        EA: Yea the traffic is not very good. What do you think about fame? Do
        you think that you're famous right now? Or my other question is, if you
        became famous how would you feel? Would you feel the same way, or do you
        have any thoughts on that?
      </p>

      <p>
        TM: Hmm...Fame is interesting...You want your music to do good. It's
        okay if people know me because they like my music...
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Q_vz1ae6ao4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        EA: So, your albums 27 and 33, are they numerically significant to you?
        For example, were you 27 years old when you released 27? Was it
        something like that?{" "}
      </p>

      <p>
        TM: Yea, so when I released 27 I had gone through probably 10 or 15
        years of my life going through different kinds of bands, going through
        all the genres really. And always wanted to do a solo album but never
        really had the songs for it. And then 27 kinda rolled around and yea,
        like I told you last time it happened really quick. There is a song on
        the album where I sing about being 27, just for a little bit of it. The
        song is called, "Vinur vina minna" which means a friend of my friends,
        which became probably the biggest single on the album at the time, and
        kind of a sing-along track I guess, and well it just, it's a powerful
        number I suppose...But yea I wrote the songs while I was 27, I recorded
        them and they came out while I was still that age.
      </p>

      <p>
        EA: Right, right yea, that was on the first album. I really liked that
        song actually.{" "}
      </p>

      <p>
        TM: Chapters in life, because albums can also become sort of, I believe
        they should be sort of like your diary for the last couple of years.
        That's why you gotta put it out while it's still fresh enough that you
        connect to it...And also numbers are kind of universal, you know, they
        are. So it's easier maybe also for people all around the world to give
        this album a chance even though it's not in their native language so to
        say. At least they realize, okay there's something here I can
        familiarize with, to project their own things onto it and and can become
        theirs, you know it's not you have to go right away to the dictionary.{" "}
      </p>

      <p>
        EA: Yea that actually is a good way to put it. That's a good approach to
        it actually, it's easy to relate to...My next question is, were you
        inspired by David Bowie?{" "}
      </p>

      <p>
        TM: Yea, very, I got to know his music when I was a bit older, got
        really into it, I would say when I was like 18. Knew just some of his
        hits before but when I was 18 I really got into his stuff, and started
        going through all the 70s albums, the catalog you know, and just really,
        really like it.{" "}
      </p>

      <p>
        EA: Yea, I noticed that some of your songs, not all of them, but maybe a
        couple remind me of his music for some reason.{" "}
      </p>

      <p>
        TM: And on the last album I did, last LP, 33, there was the first song,
        the opening track is a reference to Life on Mars...
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/8Peqg7JgNqc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        EA: Oh I see...I really like the song Sloppurinn from the 33 album. Was
        that a hard album to record?{" "}
      </p>

      <p>
        TM: Not really, it was during covid lockdowns, which meant that there
        were no concerts happening. So it was quite easy to get people to come
        to the studio, like players, whatever players you needed, they were all
        free, they didn't have all these gigs and stuff. So the recording
        process took less time, easier to schedule, so yea, and just less
        distraction, so the focus was pretty good.
      </p>

      <p>EA: Was it easier to make than your other albums? </p>

      <p>
        TM: In some ways. The first album, made it in like one summer I guess,
        and the second album took more time because we recorded it in Iceland
        and then we finished it in UK, the Orna album. And then the third album
        was done with a lot of new people, mainly in Iceland. Yea, it was quite
        smooth.
      </p>

      <p>
        EA: I noticed on your website that you include chords and lyrics for
        some of your songs. Do you generally want people to try to learn your
        music? I thought that was kind of interesting.{" "}
      </p>

      <p>
        TM: Yea, I had some requests from people who wanted, asked for the
        chords for these songs, so I made them specifically for these songs. And
        I've always been meaning to do it for my whole catalog, just to have it
        somewhere floating around if anybody's interested. It's just an honor if
        people want to learn your songs I suppose.{" "}
      </p>

      <p>
        EA: Yea, yea that's really cool. I guess musicians can be protective of
        their music but when you're learning an instrument sometimes it's fun
        just to be able to learn a song that you really like.{" "}
      </p>

      <p>
        TM: Yea, and it's better that it comes from the source. Sometimes
        there's stuff floating around and it doesn't have the right lyrics, or
        it doesn't have the right chords, you know?{" "}
      </p>

      <p>
        EA: So do you try to make classic songs? Or when you make music are you
        just trying to express every idea that you have?{" "}
      </p>

      <p>TM: What do you mean classic songs? </p>

      <p>
        EA: Well I guess a lot of your songs I listen to them and they just seem
        like classics, like I could listen to them over and over again.{" "}
      </p>

      <p>
        TM: Yea that's good, that's nice to hear man. I never work too hard on
        an idea if it's not a good one, you know? So I guess it kind of takes
        care of itself in that way...I only try to make good songs.{" "}
      </p>

      <p>
        EA: Well you have a lot of good songs, I guess that's what I'll say
        about that.{" "}
      </p>

      <p>
        TM: Thanks man. Also I understand maybe what you're getting at, like
        some artists they make 30 songs and then they choose 12 for the album or
        something and just throw the other ones away. I wouldn't say that I'm
        like that. I'm more just, most of the songs I write and complete, I put
        them out eventually.{" "}
      </p>

      <p>
        EA: Do you ever feel distant from the rest of the world? Since Iceland
        is a distant country, do you feel the distance? Or do you feel connected
        with everybody?
      </p>

      <p>
        TM: We are isolated but we are also sort of between the continents,
        Europe and America, and so a lot of people stop over in Iceland on their
        way back and forth. So we are like a classic stopover place, so we feel
        very cosmopolitan, but we are very isolated in reality if this wasn't
        the case. For example, a couple years back I flew to Greenland which is
        our neighbor, had some shows over there, and there I felt the isolation
        much more because they don't have as much transportation and traffic,
        and they don't have such good internet connection and stuff like that.
        So you kind of realize where you're at in the world, you're very far
        away from everything. I think in Iceland we have had good internet
        connection for a long time, we have a lot of tourism and traffic, so we
        kind of feel like we're in the middle part of the world. But we are
        quite far away from everything that's true. It's more expensive to tour
        than if you would live in Europe or you know on the continent somewhere.
      </p>

      <p>
        EA: Yea, are you in more bands that I don't know about? So I know about
        your solo work and I know about your reggae band, but are there other
        bands or side projects that you're a part of?{" "}
      </p>

      <p>
        TM: At the moment none of them are active, no other projects that I've
        been in. From the past I've been in many bands and projects, but at the
        moment I'm mostly working on my solo stuff and collaborations that come
        up. But there is one project, I've been working with a Danish musician,
        we've been doing music together in English. We haven't put out any of
        our songs yet but it's called Supernatural Suburbia. I think we have an
        Instagram site so hopefully we'll be putting that out soon, it's
        exciting I guess.{" "}
      </p>

      <p>
        EA: Yea that's really interesting, I didn't know about that. So did you
        guys already record some songs?{" "}
      </p>

      <p>
        TM: Yea maybe like seven songs written down and started recording, then
        hopefully get them out next year I hope.
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665004610/artnow/teitur/5-min.jpg"
        alt="Teitur Magnússon from Iceland on artnow.world"
      />

      <p>
        EA: Yea, that's really exciting, I'll have to try to follow up on
        that...I noticed that there's a flying saucer in the corner of one of
        the Ojba Rasta albums, the album cover has a lion riding a horse. Do you
        believe that there could be some form of life in the universe besides
        ourselves?{" "}
      </p>

      <p>
        TM: Yea I think it's possible, definitely. I have no reason to doubt it,
        but I don't have any proof. But sometimes I feel like we are all in some
        way or another, we are all aliens, alien life.
      </p>

      <p>
        EA: Yea, some people say that. Have you ever seen a flying saucer in the
        sky or anything, any experiences?{" "}
      </p>

      <p>TM: No I can't say I have. How about you? </p>

      <p>
        EA: I've had just two experiences but they didn't last very long. I was
        with friends and we were just kind of like, what was that? Made us
        wonder but it could have been a lot of things- maybe our imaginations,
        or all sorts of things. But it's one of those things that definitely
        just makes you wonder what's possible and stuff...Are you a spiritual
        person? Just wondering.{" "}
      </p>

      <p>
        TM: Yea, yea I believe in the spirit. I don't know how to answer that in
        other ways. I guess I'm interested in different kinds of religions and
        spirituality, and try to find some common ground. We have some history
        in Iceland with paganism and the belief in the Nordic gods, the god of
        thunder and all that, and then Christianity. I like to mix these things
        up, and then check out everything that's useful, if it's yoga or
        Hinduism or Buddhism or whatever.{" "}
      </p>

      <p>
        EA: A lot of the Eastern religions are really interesting, they have a
        lot to teach. Do you think you could do any style of music? Like how you
        went from your style that you usually do to reggae seems like a really
        big jump for any artist to accomplish. Do you think that you could just
        do any type of music if you wanted to?
      </p>

      <p>
        TM: Maybe. Probably if you put your mind to it you can see what happens,
        it's hard to say, you know? Some forms of music take a lot of practice
        and discipline, classical music and so forth. So you don't know until
        you try it out. And other forms of music are so much based on
        connectivity to some kind of rawness, which you can't fake. So I'm
        somewhere in the middle.
      </p>

      <p>
        EA: Yea, that's a good point. It reminds me of the Blues. Some people
        say if you haven't experienced pain in your life you can't play the
        blues, or something like that. I was going to ask you actually, have you
        ever tried blues music?
      </p>

      <p>
        TM: Yea, I played a lot of it on guitar as a kid and I was in a blues
        band in high school. Yea dabbled in it I guess. It's often the first
        kind of music that guitar players start to really like when they start
        to learn how to play solos on a guitar. You know it's the first music we
        really easily learn the scales and stuff...sort of the space is for rock
        music as well and then you just kind of take it from there.{" "}
      </p>

      <p>
        EA: Yea, that's really cool. It's good to hear that you used to try to
        play that type of music. I really like blues music, you know Jimi
        Hendrix and stuff. It's just it's so, I don't know, it's kind of hard
        really, like it takes so much practice and stuff. I mean it's easy but
        it's also hard, if that makes sense. So you have a knack for creating
        relaxing songs and like I referred to before, like "classic" sounding
        songs. Do you live an easy going life?
      </p>

      <p>TM: Yea, I would say so yes.</p>

      <p>EA: Do you think you have a good sense of humor? </p>

      <p>TM: I hope so.</p>

      <p>
        EA: Okay my other question is, do you think that maybe you're an
        artistic genius or a musical genius? And do you have any thoughts about
        genius?{" "}
      </p>

      <p>
        TM: It's probably for other people to judge. And that word, as they say,
        has been thrown around a lot. I try to be genuine, so in that way I hope
        it comes across.
      </p>

      <p>
        EA: Yea, I've asked the question a few times to people and everybody
        seems to have the same answer, that it's for other people to decide.
      </p>

      <p>
        TM: I guess if you answer, yes I'm a genius, you're automatically an
        idiot...
      </p>

      <p>(Laughter)</p>

      <p>
        EA: Have you ever experienced coincidences? It's also called
        synchronicity, where there's like a coincidence that lines up perfectly,
        do you experience those things?{" "}
      </p>

      <p>TM: Yea all the time, everything, all the time.</p>

      <p>
        EA: Do you think maybe that's sort of how everything came together with
        you for music? That everything just kind of fell into place a little
        bit?{" "}
      </p>

      <p>
        TM: Yea, I would like to believe that. As I've said many times that we
        can never take anything for granted, and every song that comes to you,
        every opportunity that comes to you, you've got to be grateful for it
        and if you lose that then maybe you're in the wrong lane, you should try
        something else, you know? So I don't expect anything, I'm just grateful
        for everything I've had. And you can't expect the world to just let you
        be doing whatever you feel like. You have to work for it, but also
        understand that it's not all up to you what you want. It's a whole
        thing.{" "}
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665004610/artnow/teitur/1-min.jpg"
        alt="Teitur Magnússon from Iceland on artnow.world"
      />

      <p>
        EA: Do you think the Icelandic language is more expressive than the
        English language?{" "}
      </p>

      <p>
        TM: Sometimes for myself, yea, because it's my native language. I'd say
        that. It's good for certain things and English is probably great for
        other things, you know, different subjects or what you want to express
        or talk about. Languages also have different sounds to them, like music
        or genres. Some music is better in specific languages, for that reason.
        And I once had a philosophy teacher who was teaching the ancients and he
        said in his opinion philosophy could only be practiced in ancient Greek
        language. That was the best. So to each their own, some people have
        ideas like that, you know?
      </p>

      <p>
        EA: Oh yea. What do you think of the legend of the guitar player who
        sells their soul to learn how to play guitar? Do you think, I'm not sure
        how to phrase it, but I was just wondering like, what you think of that
        idea? I guess that would be like a shortcut.
      </p>

      <p>
        TM: It's a cool story, I like it. It's much more fun than just the guy
        had to practice a lot. That sounds kind of nerdy but that's probably
        closer to the truth. But there is some truth, there's some element to it
        that you have. Some people kind of sell their souls for something that
        they think they want in life. In many ways. And it's also just kind of,
        I was talking to another musician I met last night and we were talking
        about how it's interesting sometimes what musicians sing about it comes
        true, it manifests in their life. So he was talking about how he grew up
        in the 90s and he loved grunge music and all his idols were dead. And he
        was surprised that Chris Cornell, for example, died, you know committed
        suicide, how old was he, like 50 something? And he was like, it was so
        weird to do it at that time in his life. But then again, he said, if you
        look at his lyrics he's calling for help in every song almost. So there
        is something maybe you manifest also, in what you sing every night.
        That's at least part of what I believe, maybe explains why my music is
        how it is, I'm trying to manifest happiness, but it has to come from a
        genuine place. So it's not about having some fake happy mask on, but
        just kind of expressing where I'm at.
      </p>

      <p>
        EA: Yea that's really interesting. There might be some truth to that,
        what you sing about can manifest things. Sometimes when I'm reading a
        book there's certain lines that kind of seem prophetic almost...So
        there's a quote that I really like, I don't remember who said it but I
        thought I would mention it to you and see if you have anything to say
        about it. It says, "the artist's striving is not to mystify but to
        express."
      </p>

      <p>
        TM: Mm-hmm...Yea that could be also said about the poet and the
        philosopher. But a lot of artists all they do is mystify, simple stuff.
        Maybe they're very simple and they just mystify themselves. I don't
        know, I'm just saying. But yea great art should definitely do that:
        genuine expression, and then somebody out there connects with it and
        it's healing, and it's not about some superficial showing off...
      </p>

      <p>EA: Do you think your music tells a story?</p>

      <p>TM: Yea, I think so.</p>

      <p>EA: I was going to ask you, Kamelgult, what is that song about?</p>

      <p>
        TM: It's a lyric by my cousin, he wrote those lyrics, and it's...You
        want me to translate the lyrics? It's called camel yellow. Kamelgult
        means camel yellow, it's just the name of a color. Like a camel, you
        know, yellow like a camel.{" "}
      </p>

      <p>
        EA: Oh okay, I was watching the music video last night and it has some
        old people smoking cigarettes. I guess I was just trying to understand
        what's going on.
      </p>

      <p>
        TM: The lyric goes, camel yellow, and then he starts counting things
        that are camel yellow. Camel yellow coats and fingers, it's like how the
        fingers can get yellow from smoking. And then camel yellow like papers
        in books, like old books, camel yellow pages. And then now my camel
        yellow soul is singing some camel yellow nonsense, and then it just goes
        the same lyrics over and over again.{" "}
      </p>

      <p>
        EA: Like I said before, I really like that song a lot, it's very
        catchy...So let's talk about the Orna music video. I thought that the
        music video maybe is kind of like about, well it definitely seems
        philosophical, and there's some, I kind of get like a impression of
        shamanism. The different versions of you are sitting around the fire and
        then there's one that seems like a shaman. But then I'm also, I've been
        trying to analyze the music video. I don't know exactly, maybe it's
        about being afraid of something, or maybe it's about being open to
        different ways of being. I was gonna ask you what the music video means?
      </p>

      <p>
        TM: Mm-hmm...I haven't thought about the video for a long time. Two of
        my friends helped me make it, my friend Magnús Björn he wrote the script
        for the music video. And it was based on a lot of mystical experiences
        that he had, and we had together and, or like we all go through in life.
        And the script was much longer, a lot of detailed scenes were cut out to
        make it just possible to create, it was kind of complex. The director
        Logi Hilmarsson who made the video, he directed it into this story, and
        I don't think I can elaborate too much about what it's about, but it's
        about basically finding yourself and finding love, I suppose.
      </p>

      <p>
        EA: Yea, I got that impression and then at the same time it could be
        interpreted different ways. So maybe there is no right way to understand
        it.{" "}
      </p>

      <p>TM: Yea, yea just want to keep it kind of open like that.</p>

      <p>
        EA: But yea I did kind of get that feeling also, that you're all the
        characters at the same time but then like the main character they do
        seem like they're trying to understand things, and maybe yea that makes
        sense, finding yourself. It's very philosophical, it's very, yea,
        mystical and it just makes me think, it also kind of makes me laugh. And
        just so good. The other thing that I was wondering about is, I think it
        shows you walking towards a big statue in the beginning. Is that a real
        statue?{" "}
      </p>

      <p>
        TM: No it's based on a statue that was found in the ground here from a
        thousand years ago. I think it's a statue of, some people believe it's a
        statue of Thor or a statue of Freyr, the fertility god.{" "}
      </p>

      <p>
        EA: Oh yea that's really cool. So I guess some version of that statue
        actually exists over there?
      </p>

      <p>
        TM: No the statue they found was smaller. The size of your head, you
        know? We don't have any huge buildings from back in the day, we don't
        really have anything here except our language and our sagas. We don't
        have any huge old buildings and stuff.{" "}
      </p>

      <p>
        EA: Yea, right. It's still really interesting though. Oh, I was going to
        ask you, what does the word Orna mean?{" "}
      </p>

      <p>TM: Orna means to get warm.</p>

      <p>EA: Interesting...</p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/MZqD1gIDCwU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>
        TM: So it has sort of a cozy connotation, it is I guess a cozy album. So
        like the first album 27, it's very intense time in a lot of people's
        lives and it has these transformative connotations to it, and then you
        have sort of, Orna it's kind of about maybe settling down after that.
      </p>

      <p>
        EA: It's really interesting, there's a word in Spanish I think it's
        "horno" and that means oven. Warm and oven, I'm seeing some sort of
        similarity there, but I'm sure Icelandic and Spanish are not really
        related, but I just was thinking about that. Okay so my next question
        is, do you believe that freedom is a big part of being an artist?{" "}
      </p>

      <p>
        TM: Yea, yea it probably is some kind of striving for freedom, the act
        in and of itself to be an artist, just to do art, it's you know some
        playfulness, something that is not purely practical in just gathering
        food or shelter. It's something practical for the spirit, I suppose.
        That some people can't see, try to maybe hinder it in a way. So in that
        way it's a strive for freedom.
      </p>

      <p>
        EA: Yea it's something that I've been thinking about off and on. I got
        the idea from an American writer from the 1950s he wrote a book of
        essays and one of the things that he wrote he said something about, that
        the people who view the art sometimes don't have freedom in their own
        lives, so them experiencing the art gives them a chance to have some
        sort of experience of freedom, which the artist usually tries to strive
        for.
      </p>

      <p>TM: Mm-hmm, yea... </p>

      <p>EA: Do you think there's more to life than money? </p>

      <p>TM: Hmm yes. Yea I hope so.</p>

      <p>
        EA: The other thing I was wondering about is, does weed exist in
        Iceland?{" "}
      </p>

      <p>
        TM: Yes. Yea, yea it's locally grown in greenhouses, geothermal energy.
        It's illegal at the moment but hopefully it's gonna be decriminalized
        little by little. We have got CBD oils, finally they are legal, so it's
        you know, it's coming along.{" "}
      </p>

      <p>EA: Wow yea. Do you have an opinion on psychedelics?</p>

      <p>
        TM: Yea, I think they're mostly a positive thing. Probably one of the
        safest recreational things to get high on.{" "}
      </p>

      <p>
        EA: Supposedly mushrooms they say is the least damaging thing that you
        could take. Have you had any inspiration from psychedelic experiences or
        anything like that?
      </p>

      <p>
        TM: Mostly in the way you hear music. It can really enhance the
        listening experience, I feel. And in that way it affects how you create
        later on and how you produce sounds for recordings.
      </p>

      <p>
        EA: That's really cool. So I guess Iceland isn't very different, I mean,
        seems like there's still some similar things to other parts of the
        world...How do you feel about people who don't believe in you? Have you
        ever had the experience where you think you're doing something great,
        but someone else doesn't see it?
      </p>

      <p>
        TM: No, I don't know. I don't feel like that has been a big problem in
        my life.{" "}
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1665004610/artnow/teitur/2-min.jpg"
        alt="Teitur Magnússon from Iceland on artnow.world"
      />

      <p>
        EA: You probably have a lot of supportive people, so that's good
        actually. I think maybe that might be more common in America, some
        people are very critical and stuff.
      </p>

      <p>
        TM: Also my journey through music for example has been just, little by
        little people take notice of it, and those who enjoy it they listen to
        it, and others they don't have to; they probably don't know me because
        my stuff is not being pushed in anybody's face too much. I feel I'm not
        a celebrity in that way. People who know me are the people who know the
        music and I like to keep it that way, because people get annoyed if you
        get too big too fast I suppose, suddenly you're in their face and then
        you can get a lot of hate. I've been blessed I have not had to deal with
        that.{" "}
      </p>

      <p>EA: Yea that's good. Do you remember your dreams? </p>

      <p>TM: Sometimes, and if I do I try to write them down.</p>

      <p>EA: Do you get any song ideas from your dreams? </p>

      <p>
        TM: No but you never know, maybe one day it'll happen, that would be
        great.{" "}
      </p>

      <p>EA: Can you tune your guitar by ear?</p>

      <p>
        TM: Sometimes, yea I think I've gotten to that point. Get the standard
        tuning down, yea I think so.{" "}
      </p>

      <p>
        EA: Yea that's really cool. Is it easy for you to think of new song
        ideas?
      </p>

      <p>
        TM: Sometimes it is, but I never tried to force it because it's
        impossible to do that. And what I feel like is, if I have songs lined up
        that I have not recorded and they're not ready to put out, then there
        comes a point where I just stop writing new songs, and everything in my
        system is telling me to finish those songs that I have, get them out
        there to create space to get to create more songs.
      </p>

      <p>
        EA: That kind of reminds me of this other question that I had here in my
        notes. Do you ever feel like you're giving great things away that you
        should keep for yourself?
      </p>

      <p>TM: No. I think just, the more you give, the more you get.</p>

      <p>
        EA: Yea, I really like that answer, something to think about. Yea that's
        interesting...Do you think there's a such thing as ghosts?{" "}
      </p>

      <p>
        TM: I was really into ghosts as a kid. There's a lot of Icelandic ghost
        stories, folklore that my father used to read me, and probably made me
        afraid of the dark for eternity. But at the same time the moral of all
        those stories, most of them at least, is that the only thing to fear is
        fear itself. That the ghosts are mostly there to scare you, they don't
        really harm you physically, but in those stories they mostly make people
        do stupid stuff because they get so freaked out that they end up hurting
        themselves or their fellows...Yea, I've never seen a ghost but once you
        start thinking too much about them, especially here in the dark during
        winter in the north, you start feeling them. So it's maybe a question of
        manifestation.{" "}
      </p>

      <p>
        EA: Building off of that question, have you ever had any supernatural or
        paranormal experiences?
      </p>

      <p>
        TM: Yea, when I was 23 I survived a very crazy car crash almost intact.
        Car flipped four times, like went four circles off the roads, and I was
        with my friend in the car, he had a cut here on his head because he hit
        the dashboard. But other than that I'm just so happy that I got through
        that and my friend. There was a little dangling scent ornament in the
        rear view mirror with a picture of Jesus on it, in like psychedelic
        colors, maybe he was there with me. It was on a road trip in Iceland,
        and I was definitely searching for some purpose. Yea that was a
        paranormal experience, one of many, but probably one of the biggest ones
        I feel.{" "}
      </p>

      <p>
        EA: Yea I see. So I guess what you're saying is it got very dangerous
        and then you looked in the mirror and there was the image of Jesus?
      </p>

      <p>
        TM: Yea well, like I just, I didn't have to look for the purpose
        anymore, I had to just be myself and then it would manifest itself.
        Instead of running around and creating chaos, just kind of settle down,
        calm down more. Maybe explains better my laid back existence these days.
      </p>

      <p>
        EA: Yea that's a really meaningful story, I like that. So there's this
        writer in America, he wrote a novel that was kind of like a satire type
        of novel. There's this one line in the book that really floored me when
        I read it and it stayed with me ever since, it's so philosophical and it
        just makes me think. The line says, "defeat is good for art" so I guess
        the idea is that failures and defeat make the artist a better person. Do
        you believe that that's true?
      </p>

      <p>
        TM: Yea, if it doesn't kill you, you know, makes you stronger. And that
        is if it doesn't kill the artist in you also, that can also happen. But
        pain is the greatest teacher right? So in that way failure is helpful.
        And then nothing is perfect...Fail better somebody said.
      </p>

      <p>
        EA: Oh yea, I guess it's just encouragement maybe that things aren't
        always perfect…
      </p>
      <br />
      <br />
      <br />
      <h2>
        <Link to="/">artnow.world</Link>
      </h2>
    </Layout>
  );
};

export default Teitur;
